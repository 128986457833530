<template>
  <div></div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import { queryDownloadInfo } from "@/api/home";
import { lightPackege } from '@/utils/common'
import { base64Encode } from '@/utils/strUtil'
import { isValidBrowser, addLiquidId, getIP, clickReport } from '@/utils/download'

export default {
  name: "downLoad",
  data() {
    return {
      type: 'Android'
    }
  },
  async created() {
    if (this.isAndroid) {
      this.type = 'Android'
    }
    if (this.isIphone()) {
      this.type = 'IOS'
    }
    await this.innitChannelInfo()
  },
  methods: {
    async innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      deviceInfo.packageCode = this.$router.currentRoute.query.pack || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = this.$router.currentRoute.query.icode || ''
      deviceInfo.appId = this.$router.currentRoute.query.id || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let clickId = that.$router.currentRoute.query.clickid
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      await getIP( async function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          clickId:clickId,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        await addLiquidId(params)
        await that.getChannelInfo()
        // sessionReport({ ip })
      })
    },
    getChannelInfo() {
      let params = {"data":{"packageCode": this.$router.currentRoute.query.pack || process.env.VUE_APP_CHANNEL_CODE}}
      var link = document.createElement('a');
      link.download = '';
      queryDownloadInfo(params).then(res =>{
        // const url = `${this.appInfo['htmlDomainUrl']}?a=${getQueryString('a')}`
        // const { appId, appName } = this.deviceInfo
        let appId = this.$router.currentRoute.query.id||process.env.VUE_APP_APP_ID
        let appName = res.data.appName
        // 是否为海外版轻量包，轻量包域名
        let { foreign, prefix } = res.data
        // a参数 base64加密
        let parameter_a = base64Encode('##' + (this.$router.currentRoute.query.pack||process.env.VUE_APP_CHANNEL_CODE) + '####' + appId + '##')
        // 解析a参数，拼接轻量包类型数据
        let aStr = window.atob(parameter_a) + foreign + '##'
        let endecodeA = window.btoa(aStr)
        let clickid = this.$router.currentRoute.query?.clickid||''
        let a = encodeURIComponent(`${appId}$${appName}$${endecodeA}$${prefix}$${clickid}`)
        let url = `https://jack2030.gitlab.io/pool/${process.env.VUE_APP_ENV}/web.html?a=${a}`

        switch (this.type) {
          case 'Android':
            link.href = res.data.infoList[1].shopDownloadUrl;
            clickReport({
                appId: this.$router.currentRoute.query.id || process.env.VUE_APP_APP_ID,
                channelCode: this.$router.currentRoute.query.pack || process.env.VUE_APP_CHANNEL_CODE,
                type: 2 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
              }, () =>{
                link.click()
              })
            break
          case 'IOS':
            window.fetchAndDec(res.data.logoUrl).then((icon_src) => {
              // 16位编码数据（相同应用数值相同，不同应用数值不一样）
              let appIdStr = '33BC3CC7-18CE-4F97-9CF7-A24B608A212E'
              // 用渠道code进行替换
              let channelCode = aStr.split('##')[1].toUpperCase()
              appIdStr = appIdStr.replace(appIdStr.substring(appIdStr.length - channelCode.length), channelCode)
              // console.log('加密后的a参数----', appIdStr);
              // lightPackege(icon_src, url, appName, getUUID(), foreign)
              clickReport({
                    appId: this.$router.currentRoute.query.id || process.env.VUE_APP_APP_ID,
                    channelCode: this.$router.currentRoute.query.pack || process.env.VUE_APP_CHANNEL_CODE,
                    type: 3 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
                  }, () =>{
                    lightPackege(icon_src, url, appName, appIdStr)
                  })
            }).catch(err=>{})
            
        }
      }).catch(()=>{})
    },
    isAndroid() {
      let e =navigator.userAgent.toLowerCase()
      let n = /Android/i.test(e)
      return n
    },
    isIphone() {
      let e =navigator.userAgent.toLowerCase()
      let t = /iPhone|iPad|iPod/i.test(e);
      return t
    }
  }
}
</script>

<style scoped>

</style>